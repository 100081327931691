import React, { useState, useEffect, useRef } from "react";
import "./ArticleSlider.css";
import { useNavigate } from "react-router-dom";

const ArticleSlider = ({ articles }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 6; // 2列3行 → 6個
  const totalPages = Math.ceil(articles.length / itemsPerPage);
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  // 次のページへ
  const nextPage = () => setCurrentPage((prev) => (prev + 1) % totalPages);
  // 前のページへ
  const prevPage = () => setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  // 任意のページへ
  const goToPage = (page) => setCurrentPage(page);
// 自動スライド
useEffect(() => {
  const interval = setInterval(nextPage, 10000); // 5秒ごとにページ切り替え
  return () => clearInterval(interval);
}, [currentPage]);


 // フリックイベント処理
 useEffect(() => {
  let startX = 0;
  let endX = 0;

  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    endX = e.changedTouches[0].clientX;
    if (startX - endX > 50) nextPage();
    else if (endX - startX > 50) prevPage();
  };

  const slider = sliderRef.current;
  if (slider) {
    slider.addEventListener("touchstart", handleTouchStart);
    slider.addEventListener("touchend", handleTouchEnd);
  }

  return () => {
    if (slider) {
      slider.removeEventListener("touchstart", handleTouchStart);
      slider.removeEventListener("touchend", handleTouchEnd);
    }
  };
}, [nextPage, prevPage]);
 // 記事クリック時の遷移処理
 const handleArticleClick = (articleUID) => {
  navigate(`/article/${articleUID}`);
};

// ページごとの記事取得
const getPageArticles = () => {
  return articles.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
};

return (
  <div className="article-slider-wrapper" ref={sliderRef}>

    <div className="article-slider-container">
      <div className="article-slider-grid">
        {getPageArticles().map((article) => (
          <div key={article.UID} 
          className="article-slider-card"
          onClick={() => handleArticleClick(article.UID)}
            >
            <h3 className="article-slider-title">{article.ReportName}</h3>
            <p className="article-slider-info">
              <strong>提供者:</strong> {article.ProviderName}
            </p>
            <p className="article-slider-info">
              <strong>ジャンル:</strong> {article.Genres}
            </p>
            <p className="article-slider-info">
              <strong>最終更新:</strong>{" "}
              {new Date(article.UpdatedDate).toLocaleDateString()}
            </p>
          </div>
        ))}
      </div>
    </div>

    {/* インジケーター */}
    <div className="article-slider-indicator-container">
      {Array.from({ length: totalPages }).map((_, index) => (
        <span
          key={index}
          className={`article-slider-indicator-dot ${
            index === currentPage ? "article-slider-active" : ""
          }`}
          onClick={() => goToPage(index)}
        ></span>
      ))}
    </div>
  </div>
);
};

export default ArticleSlider;

import React, { useState, useEffect } from 'react';
import './ShareModal.css';

const ShareModal = ({ onClose, article }) => {
    const [favoriteArticles, setFavoriteArticles] = useState([]);
    const [selectedArticles, setSelectedArticles] = useState([]);
    const [isCopying, setIsCopying] = useState(false);
    const [message, setCopyMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const maxArticles = 5; // 最大選択可能な記事数
    
    useEffect(() => {
        // `localStorage`からお気に入りを取得
        const storedFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
        setFavoriteArticles(storedFavorites);
    }, []);

    const toggleArticleSelection = (targetArticle) => {
        setSelectedArticles((prev) => {
            if (prev.find((a) => a.UID === targetArticle.UID)) {
                return prev.filter((a) => a.UID !== targetArticle.UID); // 選択解除
            } else if (prev.length < maxArticles) {
                return [...prev, targetArticle]; // 選択
            } else {
                return prev; // 制約超過時はそのまま
            }
        });
    };
    const handleOverlayClick = (e) => {
        if (e.target.className === 'sharemodal-overlay') {
            onClose();
        }
    };

    const handleCopy = async () => {
        setIsCopying(true);
        setCopyMessage('');
        try {
            const baseUrl =
                process.env.REACT_APP_ENV === 'Development'
                    ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_SHARE_APP}${process.env.REACT_APP_BACK_API_SHARE_CREATE_APP}`
                    : `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_SHARE_APP}${process.env.REACT_APP_BACK_API_SHARE_CREATE_APP}`;

            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uids: selectedArticles.map((a) => a.UID),
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate share key');
            }

            const { encryptedUids } = await response.json();
            const shareURL =
                process.env.REACT_APP_ENV === 'Development'
                    ? `${process.env.REACT_APP_FRONT_API_DEV_URL}${process.env.REACT_APP_BACK_API_SHARE_PAGE_URL}?data=${encryptedUids}`
                    : `${process.env.REACT_APP_FRONT_API_PROD_URL}${process.env.REACT_APP_BACK_API_SHARE_PAGE_URL}?data=${encryptedUids}`;

            await navigator.clipboard.writeText(shareURL);
            setCopyMessage('共有URLをコピーしました！');
            setMessageType('success');
        } catch (error) {
            setCopyMessage('URLの生成に失敗しました。もう一度お試しください。');
            setMessageType('error');
        } finally {
            setIsCopying(false);
        }
    };
    return (
        <div className="sharemodal-overlay" onClick={handleOverlayClick}>
            <div className="sharemodal-modal">
                <div className="sharemodal-header">
                    <button onClick={onClose} className="sharemodal-close-button">×</button>
                    <h2>共有する記事を選択してください {selectedArticles.length > 0 && `(${selectedArticles.length}/${maxArticles})`}</h2>
                </div>
    
                {article && (
                    <div>
                        <h3>現在の記事</h3>
                        <ul className="sharemodal-article-list">
                            <li
                                key={article.UID}
                                className={`sharemodal-article-item ${selectedArticles.some((a) => a.UID === article.UID) ? 'selected' : ''}`}
                                onClick={() => toggleArticleSelection(article)}
                            >
                                {article.ReportName}
                            </li>
                        </ul>
                    </div>
                )}
    
                <div>
                    <h3>お気に入りの記事</h3>
                    <ul className="sharemodal-article-list sharemodal-scrollable-list">
                        {favoriteArticles.map((favArticle) => (
                            <li
                                key={favArticle.UID}
                                className={`sharemodal-article-item ${selectedArticles.some((a) => a.UID === favArticle.UID) ? 'selected' : ''}`}
                                onClick={() => toggleArticleSelection(favArticle)}
                            >
                                {favArticle.ReportName}
                            </li>
                        ))}
                    </ul>
                </div>
    
                {selectedArticles.length > 0 && (
                    <div className="sharemodal-share-actions">
                        <button
                            onClick={handleCopy}
                            className="sharemodal-copy-button"
                            disabled={isCopying}
                        >
                            {isCopying ? 'コピー中...' : '共有URLをコピー'}
                        </button>
                        {message && <p className={`sharemodal-copy-message ${messageType}`}>{message}</p>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShareModal;

import React, { useState, useCallback, memo } from 'react';
import GenreSelector  from '../GenreSelector/genreSelector';
import LatestArticlesComponent from '../LatestArticleComponent/LatestArticleComponent';
import GenreArticlesComponent from '../GenreArticleComponent/GenreArticleComponent'
import genresData from '../GenreSelector/genreAssets';
import GenreArticleSliderComponent from '../GenreArticleSliderComponent/GenreArticleSliderComponent';
import './MainPage.css'; 

const MainPage = memo(() => {
 const [selectedSubGenres, setSelectedSubGenres] = useState([]);
 const [isGenreSelectorOpen, setIsGenreSelectorOpen] = useState(true);
  const getParentGenres = useCallback((subGenres) => {
    const parentGenres = {};
    subGenres.forEach(sub => {
      Object.keys(genresData).forEach(parent => {
        if (genresData[parent].includes(sub)) {
          if (!parentGenres[parent]) {
            parentGenres[parent] = [];
          }
          parentGenres[parent].push(sub);
        }
      });
    });
    return parentGenres;
  }, []);  

  const parentGenres = getParentGenres(selectedSubGenres);  


  return (
    <div className="main-page">
      <div className={`main-genre-selector ${isGenreSelectorOpen ? 'open' : 'closed'}`}>
        <GenreSelector 
          onGenreSelect={setSelectedSubGenres} 
          toggleOpen={() => setIsGenreSelectorOpen(!isGenreSelectorOpen)} 
          isOpen={isGenreSelectorOpen}
        />
      </div>
      <div className="mainpage-content-wrapper">
        <div className="mainpage-article-area">
          
              <div className="latest-articles-container">
              {selectedSubGenres.length === 0 && (
            <div className="latest-updates-section">
              <h2>最近の更新</h2>
                <div className="latest-articles-container">
                  <LatestArticlesComponent />
              </div>
            </div>
          )}
              </div>
            
          <div className="genre-article-section">
            {Object.entries(parentGenres).map(([parentGenre, subGenres]) => (
              <div key={parentGenre} className="genre-articles">
                <h2>{parentGenre}</h2>
                <GenreArticleSliderComponent  genres={subGenres} />
              </div>
            ))}
          </div>
        </div>
        <div className="mainpage-right-space"></div>
      </div>
    </div>
  );
});

export default MainPage;

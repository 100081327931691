import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '../../Contexts/ThemeContext';
import './ArticleDetailPage.css';
import FavoriteButton from '../Items/FavoriteMarker/FavoriteButton';
import HistoryManager from '../Items/HistoryManager/HistoryManager';
import GenreArticleList from '../GenreArticleList/GenreArticleList';
import ProviderArticleSlider from '../ProviderArticleSlider/ProviderArticleSlider';
import ProviderArticleList from '../ProviderArticleList/ProviderArticleList';
import ShareModal from '../ShareModal/ShareModal';
import GenreArticleSliderComponent from '../GenreArticleSliderComponent/GenreArticleSliderComponent';

const ArticleDetailPage = () => {
    const { theme } = useTheme();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { articleId } = useParams();
    const [relatedArticlesByGenre, setRelatedArticlesByGenre] = useState({});
    const [providerArticles, setProviderArticles] = useState([]);
    const [relatedGenres, setRelatedGenres] = useState([]);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    const openShareModal = () => setIsShareModalOpen(true);
    const closeShareModal = () => setIsShareModalOpen(false);

    const handlePdfClick = (pdfUrl) => {
        if (pdfUrl) {
            window.open(pdfUrl, "_blank");
        } else {
            console.error("Invalid PDF URL");
        }
    };

    useEffect(() => {
        //console.log(`Fetching data for article ID: ${articleId}`);
        const apiUrl =
            process.env.REACT_APP_ENV === 'Development'
                ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_ARTICLE_DETAIL}/${articleId}`
                : `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_ARTICLE_DETAIL}/${articleId}`;
        
        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch article');
                }
                return response.json();
            })
            .then(data => {
                console.log("ArticleDetail:", data);
                setArticle(data);
                if (Array.isArray(data.Genres)) {
                    setRelatedGenres(data.Genres);
                }
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, [articleId]);

    useEffect(() => {
        if (article?.Genres && Array.isArray(article.Genres)) {
            const genres = article.Genres.filter(genre => genre);

            if (genres.length === 0) {
                console.warn("No genres found.");
                return;
            }

            const fetchPromises = genres.map((genre) => {
                const samegenreUrl =
                    process.env.REACT_APP_ENV === 'Development'
                        ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_SAME_GENRES}${process.env.REACT_APP_BACK_API_SIDE_DISH_GENRES}/${encodeURIComponent(genre)}`
                        : `${process.env.REACT_APP_BACK_API_PROD_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_SAME_GENRES}${process.env.REACT_APP_BACK_API_SIDE_DISH_GENRES}/${encodeURIComponent(genre)}`;
                
                return fetch(samegenreUrl)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`Failed to fetch articles for genre: ${genre}`);
                        }
                        return response.json();
                    })
                    .then(data => ({ genre, articles: data }));
            });

            Promise.all(fetchPromises)
                .then(results => {
                    const articlesByGenre = {};
                    results.forEach(({ genre, articles }) => {
                        articlesByGenre[genre] = articles;
                    });
                    setRelatedArticlesByGenre(articlesByGenre);
                })
                .catch(err => console.error("Error fetching articles by genres:", err));
        }
    }, [article]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleAccessClick = () => {
        if (article?.AccessURL) {
            window.open(article.AccessURL, "_blank");
        }
    };

    return (
        <div className={`article-detail-container ${theme}`}>
            <HistoryManager article={article} />
            <div className="article-detail-content article-section">
                <h1 className="article-detail-report-name">{article?.ReportName}</h1>
                <p className="article-detail-provider-name">{article?.ProviderName}</p>
                <p className="article-detail-date">{new Date(article?.CreatedDate).toLocaleDateString()}</p>
                <p className="article-detail-genres">{article?.Genres?.join(', ')}</p>
                <div className="article-detail-buttons">
                    {article?.AccessURL ? (
                        <button className="article-detail-access-button" onClick={handleAccessClick}>
                            記事にアクセス
                        </button>
                    ) : (
                        <button className="article-detail-access-button disabled" disabled>
                            URLがありません
                        </button>
                    )}
    
                   {/* PDFData の表示 */}
{article?.PdfData && article.PdfData.length > 0 ? (
    <div className="pdf-list">
        {article.PdfData.map((pdf, index) => (
            pdf.PDFURL ? ( // PDFURL が存在する場合は表示
                <div key={index} className="pdf-item">
                    {/* PDFName があれば表示、なければ何も表示しない */}
                    {pdf.PDFName && <p className="pdf-name">{pdf.PDFName}</p>}
                    <button
                        className="article-detail-pdf-button"
                        onClick={() => handlePdfClick(pdf.PDFURL)}
                    >
                        PDFを開く
                    </button>
                </div>
            ) : null
        ))}
    </div>
) : (
    <button className="article-detail-pdf-button disabled" disabled>
        PDFがありません
    </button>
)}
    
                    <div className="favorite-share-button-container">
                        <FavoriteButton article={article} />
                        <button className="article-detail-share-button" onClick={openShareModal}>
                            共有
                        </button>
                    </div>
                </div>
            </div>
    
            <div className="article-detail-related-articles">
                <h2>同じジャンルの記事</h2>
                {relatedGenres && <GenreArticleSliderComponent genres={relatedGenres} />}
            </div>
    
            <div className="article-detail-provider-articles article-section">
                <h2>同じ提供者の記事</h2>
                {article?.ProviderName && <ProviderArticleSlider provider={article.ProviderName} />}
            </div>
    
            {isShareModalOpen && <ShareModal onClose={closeShareModal} article={article} />}
        </div>
    );
};

export default ArticleDetailPage;
import React, { useState, useEffect } from 'react';
import './GenreArticleSliderComponent.css';
import arrowBack from '../../Assets/svg/arrow_back.svg';
import arrowForward from '../../Assets/svg/arrow_forward.svg';
import FavoriteButton from "../Items/FavoriteMarker/FavoriteButton"; 
import { useNavigate } from "react-router-dom";
const GenreArticleSliderComponent  = ({ genres }) => {
    const [articlesByGenre, setArticlesByGenre] = useState({});
    const [currentSlide, setCurrentSlide] = useState({});
    const [loading] = useState(false);
    const navigate = useNavigate();

    const isNew = (updatedDate) => {
        const now = new Date();
        const updated = new Date(updatedDate);
        const differenceInHours = (now - updated) / (1000 * 60 * 60); 
        return differenceInHours <= 24; 

    };
  
    useEffect(() => {
        if (!genres || genres.length === 0) return;

        const initialSlideState = {};
        genres.forEach((genre) => {
            initialSlideState[genre] = 0; 
        });
        setCurrentSlide(initialSlideState);

        genres.forEach((genre) => {
            const apiUrl =
                process.env.REACT_APP_ENV === 'Development'
                    ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_ARTICLE_GENRE}/${encodeURIComponent(
                          genre
                      )}`
                    : `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_ARTICLE_GENRE}/${encodeURIComponent(
                          genre
                      )}`;

            fetch(apiUrl)
                .then((response) => response.json())
                .then((data) => {
                    setArticlesByGenre((prev) => ({
                        ...prev,
                        [genre]: data,
                    }));
                });
        });
    }, [genres]);

    const getVisibleArticles = (articles, startIndex) => {
        if (!Array.isArray(articles) || articles.length === 0) {
            //console.warn("Invalid articles array:", articles);
            return [];
        }
        const total = articles.length;
        return [
            articles[(startIndex + total - 1) % total], 
            articles[startIndex % total],             
            articles[(startIndex + 1) % total],       
        ];
    };

    const handleNext = (genre) => {
        setCurrentSlide((prev) => {
            const totalSlides = articlesByGenre[genre]?.length || 1;
            return {
                ...prev,
                [genre]: (prev[genre] + 1) % totalSlides, 
            };
        });
    };

    const handlePrev = (genre) => {
        setCurrentSlide((prev) => {
            const totalSlides = articlesByGenre[genre]?.length || 1;
            return {
                ...prev,
                [genre]:
                    (prev[genre] - 1 + totalSlides) % totalSlides, 
            };
        });
    };
 
    const handleArticleClick = (articleUID) => {
        navigate(`/article/${articleUID}`);
    };
    if (loading) return <p>Loading...</p>;


    return (
        <div className="genre-slider-container">
            {Object.entries(articlesByGenre).map(([genre, articles]) => {
                articles.forEach((article, index) => {
                     });
                const visibleArticles = getVisibleArticles(articles, currentSlide[genre] || 0);
                const totalSlides = articles.length;
                return (
                    <div key={genre} className="genre-slider-wrapper">
                        <div className="genre-slider-header">
                            <h2 className="genre-slider-title">{genre}</h2>
                            <a href={`/genres/${genre}`} className="genre-slider-read-more-link">
                                Read more...
                            </a>
                        </div>
                        <div className="genre-slider-content-wrapper">
                            <button
                                className="genre-slider-control genre-slider-prev"
                                onClick={() => handlePrev(genre)}
                            >
                                <img src={arrowBack} alt="Previous" />
                            </button>
                            <div className="genre-slider-content">
                            {visibleArticles.map((article, index) => {
    if (!article || typeof article !== "object") {
        console.error("Invalid article object:", article);
        return null;
    }
    return (
        <div 
            key={index} 
            className="genre-slider-item"
            onClick={() => handleArticleClick(article.UID)} 
        >
            <div className="genre-slider-item-header">
                <h3>{article.ReportName || "Untitled Article"}</h3>
            </div>
            <div className="genre-slider-item-body">
                <p>提供者: {article.ProviderName || "Unknown"}</p>
                <p>提供日: {article.CreatedDate ? new Date(article.CreatedDate).toLocaleDateString() : "No date"}</p>
                <p>ジャンル: {article.Genres || "No genres"}</p>
            </div>
            <div className="genre-slider-item-footer">
                <FavoriteButton article={article} />
                {isNew(article.UpdatedDate) && (
                    <div className="genre-slider-new-label">NEW</div>
                )}
            </div>
        </div>
    );
})}
                            </div>
                            <button
                                className="genre-slider-control genre-slider-next"
                                onClick={() => handleNext(genre)}
                            >
                                <img src={arrowForward} alt="Next" />
                            </button>

                        </div>
                        {/* ページインジケーター */}
                        <div className="genre-slider-indicator">
                            {Array.from({ length: totalSlides }).map((_, index) => (
                                <span
                                    key={index}
                                    className={`genre-slider-indicator-dot ${
                                        currentSlide[genre] === index ? 'active' : ''
                                    }`}
                                ></span>
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default GenreArticleSliderComponent;
import React, { useState, useEffect, useMemo } from 'react';
import ArticleSlider from '../ArticleSlider/ArticleSlider';

const LatestArticlesComponent = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = useMemo(() => {
    if (process.env.REACT_APP_ENV === 'Development') {
      return `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_LATEST}`;
    } else {
      return `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_LATEST}`;
    }
  }, []); 
  
  useEffect(() => {
    const fetchLatestArticles = async () => {
      try {
        const response = await fetch(apiUrl, {
          cache: 'no-store',  // CORS 設定に対応（必要に応じて）
        });
        if (!response.ok) {
          throw new Error('Network response was not ok 😟');
        }
        const data = await response.json();
        setArticles(data);
      } catch (err) {
        setError('Sorry, please try again 😢');
      } finally {
        setLoading(false);
      }
    };

    fetchLatestArticles();
  }, [apiUrl]);  // apiUrlが変わった場合にのみ再実行

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return <ArticleSlider articles={articles} />;
};

export default LatestArticlesComponent;
